<template>
  <div>
    <LoadingState v-if="isLoading" />
    <a-row :gutter="16" v-else>
      <a-col
        :sm="24"
        :md="6"
        v-sticky="{ zIndex: 99, stickyTop: 10, disabled: isDisabledSticky }"
      >
        <div class="card">
          <div class="card-header card-header-flex">
            <div
              class="d-flex justify-content-between align-items-center w-100"
            >
              <div>
                <h2 class="font-size-18">Select Level</h2>
              </div>
            </div>
          </div>
          <div class="card-body">
            <Menu
              :menus="menus"
              :menuActive="menuActive"
              @menu-change="menuChange"
            />
          </div>
          <!-- <div v-if="generalReport.kelas_tahun_ajaran" class="card-body d-flex flex-column align-items-center justify-content-center">
            <div class="kit__utils__avatar kit__utils__avatar--size130 kit__utils__avatar--rounded p-1 border border-primary shadow">
              <img v-if="generalReport.kelas_tahun_ajaran.murid.jenis_kelamin === 'Laki-Laki'" src="@/assets/Male-Peci.svg" alt="User 11" />
              <img v-else-if="generalReport.kelas_tahun_ajaran.murid.jenis_kelamin === 'Perempuan'" src="@/assets/Female-Hijab.svg" alt="User 11" />
            </div>
            <div class="text-center">
              <h5>{{generalReport.kelas_tahun_ajaran.murid.nis}}</h5>
              <h5>{{generalReport.kelas_tahun_ajaran.murid.nama}}</h5>
              <h5>{{generalReport.kelas_tahun_ajaran.murid.jenis_kelamin}}</h5>
              <div class="d-flex justify-content-center align-items-center my-3">
                <a-space size="middle">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>Phone Number</span>
                    </template>
                    <a :href="generalReport.kelas_tahun_ajaran.murid.nomor_telepon ? `tel:${generalReport.kelas_tahun_ajaran.murid.nomor_telepon}` : '#'">
                      <a-button size="large" type="default" shape="circle" icon="phone" />
                    </a>
                  </a-tooltip>
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>E-Mail</span>
                    </template>
                    <a :href="generalReport.kelas_tahun_ajaran.murid.email ? `mailto:${generalReport.kelas_tahun_ajaran.murid.email}` : '#'">
                      <a-button size="large" type="default" shape="circle" icon="mail" />
                    </a>
                  </a-tooltip>
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>Parent Phone</span>
                    </template>
                    <a :href="generalReport.kelas_tahun_ajaran.murid.orangtuas[0].nomor_telepon ? `tel:${generalReport.kelas_tahun_ajaran.murid.orangtuas[0].nomor_telepon}` : '#'">
                      <a-button size="large" type="default" shape="circle" icon="alert" />
                    </a>
                  </a-tooltip>
                </a-space>
              </div>
            </div>
            <table class="table mt-2 mb-1">
              <tr>
                <td class="text-left">NISN</td>
                <td>:</td>
                <td class="text-left w-75">{{generalReport.kelas_tahun_ajaran.murid.nisn ? generalReport.kelas_tahun_ajaran.murid.nisn : '-' }}</td>
              </tr>
              <tr>
                <td class="text-left">Class</td>
                <td>:</td>
                <td class="text-left w-75">{{generalReport.kelas_tahun_ajaran.kela.tingkat}}-{{generalReport.kelas_tahun_ajaran.kela.simbol}}</td>
              </tr>
              <tr>
                <td class="text-left">SPP</td>
                <td>:</td>
                <td class="text-left w-75">
                  <a-tag :color="generalReport.kelas_tahun_ajaran.murid.status_spp === 'paid' ? 'green' : 'red'">{{generalReport.kelas_tahun_ajaran.murid.status_spp}}</a-tag>
                </td>
              </tr>
            </table>
            <a-divider >Discover</a-divider>
            <span class="mb-4"></span>
            <Menu :menus="menus" :menuActive="menuActive" @menu-change="menuChange" />
          </div> -->
        </div>
      </a-col>
      <a-col :sm="24" :md="18">
        <div class="card">
          <div class="card-header card-header-flex">
            <div
              class="d-md-flex justify-content-between align-items-center w-100"
            >
              <div>
                <h2 class="font-size-18">{{ menuName }}</h2>
              </div>
              <a-radio-group
                @change="handleReportType"
                :value="reportType"
                button-style="solid"
                size="large"
                :disabled="disableSelectType"
              >
                <a-radio-button value="mid">{{
                  reportText["mid"]
                }}</a-radio-button>
                <a-radio-button value="final">{{
                  reportText["final"]
                }}</a-radio-button>
                <!-- <a-radio-button :value="(this.detailReport.semester==='Ganjil') ? 'PAS' : 'PAT'">
                  {{(this.detailReport.semester==='Ganjil') ? (this.textRadio2='PAS') : (this.textRadio2='PAT')}}
                </a-radio-button> -->
              </a-radio-group>
            </div>
          </div>
          <div class="card-body mt-1">
            <div v-if="isFetching" class="w-100 text-center my-5">
              <a-spin>
                <a-icon
                  slot="indicator"
                  type="loading"
                  style="font-size: 5rem; color: var(--kit-color-primary)"
                  spin
                />
              </a-spin>
            </div>
            <template v-else>
              <div v-if="!isPublished" class="my-5">
                <EmptyState
                  :heading="generalReport ? 'Not Published Yet' : 'No Data'"
                  :description="
                    generalReport
                      ? 'The report is still not published.'
                      : `You still don't have ${this.reportType.toLowerCase()} report on this level.`
                  "
                />
              </div>
              <template v-else>
                <!-- <template v-if="generalReport.version === null">
                  <report-mid-view
                    v-if="reportType === 'mid'"
                    :configSchool="configSchool"
                    :generalReport="generalReport"
                  />
                  <report-final-view
                    v-else-if="reportType === 'final'"
                    :generalReport="generalReport"
                    :configSchool="configSchool"
                    page
                  />
                </template> -->
                <!-- <template v-else-if="generalReport.version == 1"> -->
                <template>
                  <report-mid-view-v2
                    v-if="reportType === 'mid'"
                    :configSchool="configSchool"
                    :dataReport="generalReport"
                  />
                  <report-final-view-v2
                    v-else-if="reportType === 'final'"
                    :dataReport="generalReport"
                    :configSchool="configSchool"
                    page
                  />
                </template>
                <!-- <EmptyState v-else-if="generalReport.type === 'final'"
                  heading="Under Maintenance"
                  description="This component is still under maintenance."
                /> -->
              </template>
              <!-- <div class="d-flex justify-content-center mb-4">
                <a-button
                  :loading="loadingExport"
                  @click.prevent="exportReport"
                  type="primary"
                  icon="file-pdf"
                >
                {{loadingExport ? 'Exporting...' : 'Export to PDF'}}
                </a-button>
              </div> -->
              <!-- <vue-html2pdf
                :html-to-pdf-options="pdfOptions"
                :show-layout="false"
                :float-layout="true"
                :enable-download="false"
                :preview-modal="false"
                :paginate-elements-by-height="1100"
                :pdf-quality="2"
                :manual-pagination="true"
                pdf-content-width="100%"
                ref="html2Pdf"
                @beforeDownload="beforeDownload($event)"
                @progress="onProgress($event)"
                @hasStartedGeneration="hasStartedGeneration()"
                @hasGenerated="hasGenerated($event)"
              >
              <section slot="pdf-content"> -->
              <!-- <report-mid-view v-if="generalReport.type === 'mid'" :configSchool="configSchool" :generalReport="generalReport" />
              <report-final-view v-else-if="generalReport.type === 'final'" :configSchool="configSchool" /> -->
              <!-- </section>
              </vue-html2pdf> -->
            </template>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import VueSticky from 'vue-sticky'
import config from '@/config'

export default {
  directives: {
    sticky: VueSticky,
  },
  components: {
    // VueHtml2pdf: () => import('vue-html2pdf'),
    LoadingState: () => import('@/components/app/LoadingState'),
    Menu: () => import('@/components/app/MenuV2'),
    // ReportMidView: () => import('@/components/app/Report/Mid'),
    // ReportFinalView: () => import('@/components/app/Report/Final'),
    ReportMidViewV2: () => import('@/components/app/Report/Mid/v2'),
    ReportFinalViewV2: () => import('@/components/app/Report/Final/v2'),
    EmptyState: () => import('@/components/app/EmptyState'),
    // ReportRp: () => import('@/components/app/Homeroom/ReportRP'),
    // ChartAllSubject: () => import('@/components/app/ChartAllSubject'),
    // StudentProgress,
  },
  data() {
    return {
      menus: [],
      menuActive: 2,
      configSchool: '',
      generalReport: {},
      loadingExport: false,
      chartData: {
        labels: [],
        series: [],
        fullWidth: true,
      },
      pdfOptions: {
        margin: [10, 10, 10, 10],
        filename: 'File',
        image: {
          type: 'jpeg',
          quality: 0.50,
        },
        html2canvas: {
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: {
          // unit: 'in',
          format: 'a4',
          orientation: 'portrait',
        },
      },
      reportType: 'mid',
      reportText: {
        mid: 'MID',
        final: 'FINAL',
      },
      disableSelectType: false,
      isPublished: false,
      isFetching: false,
    }
  },
  methods: {
    async handleReportType(e) {
      this.reportType = e.target.value
      this.reportText[this.reportType] = 'Please Wait...'
      await this.fetchDataDetail()
    },
    menuChange(payload) {
      this.menuActive = payload
      this.fetchDataDetail()
    },
    exportReport() {
      this.loadingExport = true
      this.$refs.html2Pdf.generatePdf()
    },
    onProgress(event) {
      this.loadingExport = true
      console.log('Progress  ', event)
    },
    hasStartedGeneration() {
      console.log('Start Generate')
    },
    hasGenerated(event) {
      console.log('Generated', event)
    },
    async beforeDownload ({ html2pdf, options, pdfContent }) {
      // for (let i = 0; i < 22; i++) {
      await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
        // const totalPages = pdf.internal.getNumberOfPages()
        // for (let i = 1; i <= totalPages; i++) {
        //   pdf.setPage(i)
        //   pdf.setFontSize(10)
        //   pdf.setTextColor(150)
        //   pdf.text('Hasan Educational Technology', (pdf.internal.pageSize.getWidth() * 0.50), (pdf.internal.pageSize.getHeight() - 2))
        //   pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 2))
        // }
      }).save()
      // }
      this.loadingExport = false
    },
    async fetchDataDetailOld() {
      try {
        const idMurid = this.user.id
        this.disableSelectType = true
        this.isFetching = true
        this.generalReport = {}
        const res = await this.$store.dispatch('report/FETCH_DETAIL_GENERAL_REPORT_BY_MURID',
          { idMurid, tipe: this.reportType, tingkat: this.levelAndTypeMenu.level, semester: this.levelAndTypeMenu.semester },
        )
        this.isFetching = false
        this.disableSelectType = false
        this.reportText[this.reportType] = this.reportType.toUpperCase()
        this.generalReport = res.generalReport
        this.isPublished = res.isPublished
        // console.log('this.generalReport fetch', this.generalReport)
      } catch (err) {
        console.log(err)
      }
    },
    async fetchDataDetail() {
      try {
        const idMurid = this.user.id
        this.disableSelectType = true
        this.isFetching = true
        this.generalReport = {}
        // console.log(this.levelAndTypeMenu)
        const res = await this.$store.dispatch('report/FETCH_METADATA_DETAIL_GENERAL_REPORT_BY_MURID',
          { idMurid, tipe: this.reportType, idLevel: this.levelAndTypeMenu.idLevel, semester: this.levelAndTypeMenu.semester },
        )
        // console.log(res)
        this.isFetching = false
        this.disableSelectType = false
        this.reportText[this.reportType] = this.reportType.toUpperCase()
        this.generalReport = res.generalReport
        this.isPublished = res.isPublished
        // console.log('this.generalReport fetch', this.generalReport)
      } catch (err) {
        this.isFetching = false
        this.disableSelectType = false
        this.isPublished = false
        this.$store.commit('SET_STATE', {
          isLoading: false,
        })
        this.reportText[this.reportType] = this.reportType.toUpperCase()
        console.log(err)
      }
    },
    async fetchLevels() {
      try {
        const levels = await this.$store.dispatch('master/FETCH_LEVEL', {})
        let counter = 0
        this.menus = levels.map(level => {
          counter++
          const parent = {
            id: level.id,
            key: counter,
            menu: level.nama,
            sub: true,
            children: [],
          }
          counter += 2
          const children = [
            {
              key: counter - 1,
              value: 'Ganjil',
              menu: '1st Semester',
            },
            {
              key: counter,
              value: 'Genap',
              menu: '2nd Semester',
            },
          ]
          return {
            ...parent,
            children,
          }
        })
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
  },
  async mounted() {
    try {
      this.configSchool = config.school
      this.$store.commit('SET_STATE', {
        isLoading: true,
      })
      await this.fetchLevels()
      this.fetchDataDetail()
    } catch (err) {
      this.$store.commit('SET_STATE', {
        isLoading: true,
      })
      console.log(err)
    }
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
    isLoading() {
      return this.$store.state.isLoading
    },
    levelAndTypeMenu() {
      let level = ''
      let semester = ''
      let idLevel = null
      console.log(this.menus)
      this.menus.forEach(el => {
        const find = el.children.filter(elChildren => elChildren.key === this.menuActive)[0]
        if (find !== undefined) {
          level = el.menu
          idLevel = el.id
          semester = find.value
        }
      })

      return { level, semester, idLevel }
    },
    menuName() {
      let menu = ''
      this.menus.forEach(el => {
        if (el.sub) {
          const find = el.children.filter(elChildren => elChildren.key === this.menuActive)[0]
          if (find !== undefined) {
            const semester = find.menu
            menu = 'Level ' + el.menu + ' ' + semester
          }
        } else {
          if (el.key === this.menuActive) {
            menu = el.menu
          }
        }
      })
      return menu
      // const { menu } = this.menus.filter(el => el.key === this.menuActive)[0]
      // const { menu } = this.menus.filter(el => el.children ? el.children.filter(elChildren => elChildren.key === this.menuActive)[0] : el.key === this.menuActive)[0]
      // return menu
    },
    isDisabledSticky() {
      if (!this.isDesktop) return true

      return false
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
}
</script>
